//import { DisplayLabel } from './components/DisplayLabel';

// // // import Swiper JS
// import Swiper from 'swiper';
// // import Swiper styles
// import 'swiper/css';

// import Swiper from 'swiper/bundle';

// // import styles bundle
// import 'swiper/css/bundle';

// // init Swiper:
// //const swiper = new Swiper(...);

// const swiper = new Swiper('.swiper', {
//   // Optional parameters
//   //direction: 'vertical',
//   loop: true,

//   // If we need pagination
//   pagination: {
//     el: '.swiper-pagination',
//     clickable: true,
//     dynamicBullets: true,
//     dynamicMainBullets:3
//   },

//   // Navigation arrows
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },

//   // And if we need scrollbar
//   scrollbar: {
//     el: '.swiper-scrollbar',
//     draggable:true
//   },
// });


//import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// import 'dark-mode-switch';
//import '/css/test.scss';

//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';

// import './dark-mode-switch.js';

//https://swiperjs.com/get-started
//* import all at once
import Swiper from 'swiper/bundle';
//import Swiper from 'swiper';
  //* import the Swiper styles
//import 'swiper/css';


// // import styles bundle
import 'swiper/css/bundle';




function innitSwiper() {

  const swiper = new Swiper('.swiper', {
    // Optional parameters
    //direction: 'vertical',
    // effect: "cards",
    // grabCursor: true,
    loop: true,
    autoHeight: true,
    //wrapperClass: 'wp-block-gallery',
    //slideClass: 'wp-block-image',
    //containerModifierClass:'wp-block-gallery',
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true
    },
  });
}

innitSwiper();

import Masonry from 'masonry-layout';



//import barba from '@barba/core';
//const Masonry = require('masonry-layout');
const InfiniteScroll = require('infinite-scroll');
const imagesLoaded = require('imagesloaded');

//import gsap from "gsap";




// now you can use outlayer option






//npm install flickity-imagesloadedimport PhotoSwipeLightbox from 'photoswipe/lightbox';

//https://www.lightgalleryjs.com/
// import lightGallery from 'lightgallery';

// // Plugins
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

//import { tns } from "./node_modules/tiny-slider/src/tiny-slider"


//var msnry = new Masonry( '.grid', {});

// let Main = {
//   init: async function () {

//     // initialize demo javascript component - async/await invokes some 
//     //  level of babel transformation
//     const displayLabel = new DisplayLabel();
//     await displayLabel.init();

//   }
// };

// barba.hooks.before((data) => {
//   //alert('!');
//   console.log(data.current.url.href);
//   console.log(window.location.href);
//   var current_url = data.current.url.href;
//   if (current_url == window.location.href) {
    
//   }
// }); 
// barba.hooks.beforeEnter((data) => {
//   //innit();
//   console.log('beforeEnter:innit');
//   window.scrollTo({
//     top: 100,
//     left: 100,
//     behavior: "smooth",
//   });
// });
// barba.hooks.after((data) => {
//   //innit();
//   console.log('beforeEnter:innit');
//   window.scrollTo({
//     top: 100,
//     left: 100,
//     behavior: "smooth",
//   });
// });

// if (history.scrollRestoration) {
//   history.scrollRestoration = 'auto';
// }
//alert('!');
function removeHash() {
  history.pushState("", document.title, window.location.pathname
    + window.location.search);
}
// document.addEventListener('click', function (event) {
//   removeHash();
//   //cehck same link
//   var href_closest = event.target.closest("a");
//   var current_href = window.location.href;
//   if (href_closest) {
//     if (href_closest == current_href) {
//       console.log('we blijven op deze link');
//       // dit regelen via barba.js
//       event.preventDefault();
//     }
//   }
  
  

// }, false);

// barba.init({
//  // preventRunning: true,
//   //prefetchIgnore:true,
//   cacheIgnore :true,
//   prevent: ({ el }) => el.classList && el.classList.contains('prevent'),
//   transitions: [{
//     name: 'default-transition',
//     leave() {
//       // create your stunning leave animation here
//       // console.log('Leave');
//       // document.body.classList.add("fade-out");
//     },
//     enter() {
//       // create your amazing enter animation here
//       // console.log('Enter');
//       // document.body.classList.add("fade-out-enter");
//       // st = setTimeout(function(){
//       //   document.body.classList.remove("fade-out-enter","fade-out");
//       // },1300);
      
//     }
//   }]
// });







// myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
//   alert('boe');
// })

// barba.init({
//   timeout: 10000,
//   requestError: (trigger, action, url, response) => {
//     // go to a custom 404 page if the user click on a link that return a 404 response status
//     if (action === 'click' && response.status && response.status === 404) {
//       barba.go('/404');
//     }

//     // prevent Barba from redirecting the user to the requested URL
//     // this is equivalent to e.preventDefault() in this context
//     return false;
//   },
//   transitions: [{
    
//     sync: true,
//     name: 'opacity-transition-yolo',
//     leave(data) {
//       return gsap.to(data.current.container, {x: 0, y:0, duration: .25, opacity:0});
//     },
//     enter(data) {
//       return gsap.from(data.next.container, {x: 0, y:0, duration: .4, opacity:0.4});
//     }
//   }]
// });


// barba.hooks.afterEnter((data) => {
//   //console.log(data.next.namespace);
//   //alert('console.log(data.next.namespace);');
//   var Pnav = document.getElementsByClassName('single-post-nav-wr')[0];
//   var PnavHtml = Pnav.innerHTML;
//   var Cnav = document.getElementsByClassName('outer-barba')[0];
//   Cnav.innerHTML = '';
//   Pnav.innerHTML = '';
//   Pnav.remove();
//   // console.log(Pnav);
//   // console.log('!');
//   Cnav.innerHTML = PnavHtml;

 

// });

//var Flickity = require('flickity');
//require('flickity-imagesloaded');
//require('flickity-fullscreen');

//Main.init();

// var options = {
//   // vertical offset in px before element is first unpinned
//   offset: 0,
//   // or you can specify offset individually for up/down scroll
//   offset: {
//     up: 100,
//     down: 50
//   },
//   // scroll tolerance in px before state changes
//   tolerance: 0,
//   // or you can specify tolerance individually for up/down scroll
//   tolerance: {
//     up: 5,
//     down: 0
//   },
//   // css classes to apply
//   classes: {
//     // when element is initialised
//     initial: "headroom",
//     // when scrolling up
//     pinned: "headroom--pinned",
//     // when scrolling down
//     unpinned: "headroom--unpinned",
//     // when above offset
//     top: "headroom--top",
//     // when below offset
//     notTop: "headroom--not-top",
//     // when at bottom of scroll area
//     bottom: "headroom--bottom",
//     // when not at bottom of scroll area
//     notBottom: "headroom--not-bottom",
//     // when frozen method has been called
//     frozen: "headroom--frozen",
//     // multiple classes are also supported with a space-separated list
//     pinned: "headroom--pinned foo bar"
//   },
//   // element to listen to scroll events on, defaults to `window`
//   scroller: someElement,
//   // callback when pinned, `this` is headroom object
//   onPin: function () { },
//   // callback when unpinned, `this` is headroom object
//   onUnpin: function () { },
//   // callback when above offset, `this` is headroom object
//   onTop: function () { },
//   // callback when below offset, `this` is headroom object
//   onNotTop: function () { },
//   // callback when at bottom of page, `this` is headroom object
//   onBottom: function () { },
//   // callback when moving away from bottom of page, `this` is headroom object
//   onNotBottom: function () { }
// };
// // pass options as the second argument to the constructor
// // supplied options are merged with defaults
// var headroom = new Headroom(element, options);





// if you're using a bundler, first import:
import Headroom from "headroom.js";
// grab an element
var myElement = document.querySelector(".navbar-wr");



document.addEventListener("DOMContentLoaded", function () {
   
  var fs0 = document.querySelector('.f0');
  var fs0_i = document.querySelector('.f0 i');
  var fs1 = document.querySelector('.f1');
  var fs1_i = document.querySelector('.f1 i');
  var fs2 = document.querySelector('.f2');
  var fs2_i = document.querySelector('.f2 i');
  var fs3 = document.querySelector('.f3');
  var fs3_i = document.querySelector('.f3 i');
  var gif = '3af8883117b8a4a61f14.gif';

  var st = setTimeout(function () {
    fs0_i.style.background = 'url(/wp-content/themes/niemanders2/dist/'+gif+'?start=begin0)';
    fs0.style.display = 'inline-block';
  }, 100);
  
  var st1 = setTimeout(function () {
    fs1_i.style.background = 'url(/wp-content/themes/niemanders2/dist/'+gif+'?start=begin1)';
    fs1.style.display = 'inline-block';
  }, 5000);
  var st2 = setTimeout(function () {
    fs2_i.style.background = 'url(/wp-content/themes/niemanders2/dist/'+gif+'?start=begin2)';
    fs2.style.display = 'inline-block';
  }, 2000);
  var st3 = setTimeout(function () {
    fs3_i.style.background = 'url(/wp-content/themes/niemanders2/dist/' + gif + '?start=begin2)';
    fs3.style.display = 'inline-block';
  }, 10000);





  document.querySelectorAll("#wpadminbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));
  var myOffcanvas = document.getElementById('navbarOffcanvasMd')
  var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

  

  
  var container = '.main';
  let elem = document.querySelector(container);

  // init Masonryvar container = 
  // let msnry = new Masonry('.main', {

  // });
  let msnry = new Masonry('.main', {
    itemSelector: '.grid__item',
    gutter: 0,
    //columnWidth: '.grid-sizer',
    transitionDuration: '0.15s',
    //horizontalOrder: true
    //containerStyle: null,
   // percentPosition: true

  });
  // window.addEventListener("resize", destroyMasonry);
  // function destroyMasonry() {
  //   let width = window.innerWidth;
  //   if (width < 768) {
  //     msnry.destroy();
  //   } else {
  //     msnry.reloadItems();
  //   }
  //   //alert(width);
  //   //768px
  //   //lg: 992px,
  //   //576px
  //   //
    
  // }
 


  
  InfiniteScroll.imagesLoaded = imagesLoaded;
  // make imagesLoaded available for InfiniteScroll
  let infScroll = new InfiniteScroll(elem, {
    // options...
    path: '.next',
    append: '.grid__item',
    //history: true,
    status: '.page-load-status',
    hideNav: '.pagination',
    history: 'push',
    outlayer: msnry,
    //debug: true,
    scrollThreshold: 500
    //button: '.view-more-button',
    // load pages on button click
    //scrollThreshold: false,
    // disable loading on scroll

  });
  infScroll.on('append', function (body, path, items, response) {
    msnry.layout();
    console.log('msnry.layout();');
  });
  
  // window.addEventListener("resize", function () {
  //   if (window.innerWidth < 768) {
  //     console.log('narrow');
  //   }
  //   else {
  //     console.log('wide');
  //   }
  //   msnry.reloadItems();
  //   console.log('resize');
  // });

  // var windowWidth = window.innerWidth;
  // console.log(windowWidth);
  // if(windowWidth <= 767){
  
  //   const cbox = document.querySelectorAll(".menu-item");

  //   for (let i = 0; i < cbox.length; i++) {
  //     cbox[i].addEventListener("mousedown", function(){
  //       bsOffcanvas.toggle();
  //     });
  //   }

  // }

  // var menuitem = document.querySelectorAll('.menu-item');
  // menuitem.addEventListener("mousedown", function(){
  //   alert('!');
  // });
  
  // const highlightedItems = document.querySelectorAll(".portfolio-item");
 
  // var st = setTimeout(function(){
  //   highlightedItems.forEach((userItem) => {
  //     userItem.classList.remove("hide-car");
  //   });
  // },500);
  // construct an instance of Headroom, passing the element
  const nav_element_ref = document.querySelector(".headroom-ref");
  const nav_element = document.querySelector(".navbar-wr");
  const nav_element_row = document.querySelector(".navbar-row-wr");
  const placeholder_hs = document.querySelector(".placeholder-hs");
  let pos = nav_element_ref.offsetTop;
  let height_element = nav_element.offsetHeight;
  var headroom = new Headroom(myElement, { offset: pos }); 
  // initialise
  headroom.init();

  
  console.log('pos"');
  console.log(pos);

  console.log('height"');
  console.log(height_element);
  placeholder_hs.style.height = height_element + 'px';
  msnry.layout();
  console.log('msnry.layout();-1');

});

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
}else{
  console.log('Looks like we are in production mode');
}
 